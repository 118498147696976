<template>
  <a-layout-header class="header-landing">
    <div class="container-xxl">
      <img class="py-4 mt-3 mb-4" src="../assets/logo/logoBewell2.png" alt="Logo bewell" width="180" />
    </div>
  </a-layout-header>
</template>

<script>
export default {
  name: 'HeaderComponent',
}
</script>

