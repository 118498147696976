<template>
  <div class="background-blue-10">
    <div class="container-xxl">

      <a-row align="middle" justify="center" class="page5-padding">
        <a-col :xs="24" :md="12" class="text-wrapper">
          <a-space direction="vertical">
            <img src="../assets/logo/logoBewellWhite2.png" alt="Logo bewell" width="180" >
            <br>
            <br>
            <a-typography-title :level="2" class="text-gray-1 ff-urbanist">Prueba y conoce las ventajas de
              <a-typography-text class="text-green-6">nuestra
                plataforma
              </a-typography-text>
              bewell
            </a-typography-title>
            <br>
            <a-button onclick="Calendly.showPopupWidget('https://calendly.com/reclutakam/demo-bewell-plus');return false;"
              class="btn-xl btn-secondary-landing">Agendar una demo</a-button>
            <br>

          </a-space>
        </a-col>
        <a-col :xs="24" :md="12">
          <img class=" img-fluid" src="../assets/platformImg.png" alt="banner" />
        </a-col>

      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page5Component',
}
</script>

