import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import './less/app.less';


const app = createApp(App);
app.config.productionTip = false;


app.use(Antd).mount('#app');