<template>
  <div class="page2-padding background-gray-2 ">
    <div class="container-xxl">
      <a-row>
        <a-col :span="24" :md="16" :lg="12">

          <a-typography-paragraph strong>Nuestros servicios</a-typography-paragraph>

          <a-typography-title :level="2" class="ff-urbanist fw-regular">
            <a-typography-text type="secondary" class="fw-semibold"> Aumenta la satisfacción y valoración de tus
              colaboradores
            </a-typography-text>
            con beneficios al alcance de tu compañía.
          </a-typography-title>
        </a-col>
      </a-row>
      <br>
      <br>
      <a-row>
        <a-col :xs="24" :lg="{ span: 20 }">
          <a-card style="height: '221px'" class="landing-card fs-xl">
            <a-row :gutter="16" align="middle" class="text-align justify-center">
              <a-col :md="6" :xl="4">
                <img src="../assets/icon/goldIcon.svg" alt="">
                <br class="hidden-sm hidden-md hidden-lg hidden-xl">
                <br class="hidden-sm hidden-md hidden-lg hidden-xl">

              </a-col>
              <a-col :md="18" :xl="20">

                <a-typography-paragraph strong>Benchmark y diseño <br class="visible"> de
                  beneficios
                </a-typography-paragraph>

                <a-typography-paragraph>Diseñamos una propuesta de beneficios comparando tu plan actual con las mejores
                  prácticas del mercado.</a-typography-paragraph>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :xs="24" :lg="{ span: 20, offset: 4 }">
          <a-card class="landing-card fs-xl">
            <a-row :gutter="16" align="middle" class="text-align justify-center">
              <a-col :md="6" :xl="4">
                <img src="../assets/icon/greenIcon.svg" alt="">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">

              </a-col>
              <a-col :md="18" :xl="20">
                <a-typography-paragraph strong>Plataforma conectada con compañías de seguros y sistemas de clientes
                </a-typography-paragraph>
                <a-typography-paragraph>Conectamos el ecosistema de clientes con compañías de seguros a través de un
                  onboarding
                  de administración digital.</a-typography-paragraph>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <br>
      <a-row>
        <a-col :xs="24" :lg="{ span: 20 }">
          <a-card style="height: '221px';" class="landing-card fs-xl">
            <a-row :gutter="16" align="middle" class="text-align justify-center">
              <a-col :md="6" :xl="4">
                <img src="../assets/icon/cyanIcon.svg" alt="">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">

              </a-col>
              <a-col :md="18" :xl="20">
                <a-typography-paragraph strong>Gestión de siniestralidad</a-typography-paragraph>
                <a-typography-paragraph>Desarrollamos planes de beneficios y seguros sustentables para que tu propuesta
                  de
                  valor
                  atraiga y retenga talento.</a-typography-paragraph>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :xs="24" :lg="{ span: 20, offset: 4 }">
          <a-card style="height: '221px';" class="landing-card fs-xl">
            <a-row :gutter="16" align="middle" class="text-align justify-center">
              <a-col :md="6" :xl="4">
                <img src="../assets/icon/purpleIcon.svg" alt="">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">
                <br class="hidden-sm hidden-md  hidden-lg hidden-xl">

              </a-col>
              <a-col :md="18" :xl="20">
                <a-typography-paragraph strong>Licitación y negociación</a-typography-paragraph>
                <a-typography-paragraph>Somos expertos en la negociación de contratos y apoyamos los procesos de
                  licitación
                  con
                  tecnología en la nube. Apostamos por la transparencia y la seguridad de la información.
                </a-typography-paragraph>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page2Component',
}
</script>

