<template>
  <HeaderComponent />
  <BannerComponent />
  <Page1 />
  <Page2 />
  <Page3 />
  <!--   <Page4 class="layout-content" /> -->
  <Page5 />
  <!--  <Contact /> -->
  <Footer class="background-gray-2 " />
</template>

<script>
import HeaderComponent from '../components/Header.vue'
import BannerComponent from '../components/Banner.vue'
import Page2 from '@/components/Page2.vue'
import Page1 from '@/components/Page1.vue'
import Page3 from '@/components/Page3.vue'
/* import Page4 from '@/components/Page4.vue' */
import Page5 from '@/components/Page5.vue'
/* import Contact from '@/components/Contact.vue' */
import Footer from '@/components/Footer.vue'

export default {
  name: 'LandingView',
  components: {
    HeaderComponent,
    BannerComponent,
    Page2,
    Page1,
    Page3,
    /*  Page4, */
    Page5,
    /*    Contact, */
    Footer
  }
}
</script>