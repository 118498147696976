<template>
  <div class="banner-padding background-gray-2">
    <div class="container-xxl">
      <a-row align="bottom">
        <a-col :xs="24" :md="10">
          <a-space direction="vertical">
            <a-typography-title>Tus colaboradores en <a-typography-text type="secondary">primer lugar
              </a-typography-text>
            </a-typography-title>
            <a-typography-paragraph class="fs-xl">Hacemos de tu organización un mejor lugar a través de nuestra
              plataforma
              que digitaliza la gestión de salud y beneficios.</a-typography-paragraph>
            <a-button onclick="Calendly.showPopupWidget('https://calendly.com/reclutakam/demo-bewell-plus');return false;"
              class="btn-xl btn-primary-landing">Agendar una demo</a-button>
            <br class="visible-md visible-lg visible-xl">
            <br class="visible-md visible-lg visible-xl">
            <br class="visible-lg visible-xl">
            <br class=" visible-xl">
            <a-typography-paragraph strong class="fs-xl">Ya confían en nosotros</a-typography-paragraph>
            <a-row :gutter="[{ xs: 24, sm: 16, md: 24 }]" justify="space-between">
              <a-col :xs="8" :md="6"> <img class="img-fluid" src="../assets/logo/echeverriaIzquierdoLogo.svg"
                  alt=" echeverriaIzquierdoLogo" /></a-col>
              <a-col :xs="8" :md="6"> <img class="img-fluid" src="../assets/logo/jumpittLogo.png" alt="jumpittLogo" />
              </a-col>
              <a-col :xs="8" :md="6"> <img class="img-fluid" src="../assets/logo/imedLogo.png" alt="imedLogo" /></a-col>
            </a-row>
            <br class="">
            <br class="">
          </a-space>

        </a-col>
        <a-col :xs="24" :md="14">
          <img class="img-fluid" src="../assets/bannerImg.png" alt="banner" />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerComponent',
}
</script>

