<template>
  <div class="footer-padding">
    <div class="container-xxl">
      <a-row justify="space-between">
        <a-col>
          <img src="../assets/logo/logoBewell2.png" width="180" alt="Bewell">
        </a-col>
        <a-col class="hidden hidden-xs">
          <img src="../assets/logo/ministryLogo.svg" width="200" alt="cmf">
        </a-col>
      </a-row>
      <br>
      <br>
      <a-row :gutter="[{ md: 72, lg: 96 }]">
        <a-col>
          <a-typography-paragraph class="fs-6">Estamos ubicados en <br>
            Apoquindo #4499, Piso 15, Las Condes, Santiago. <br>
            (+56) 9 4693 1921
          </a-typography-paragraph>

          <a-button class="px-0" type="link" href="https://cl.linkedin.com/company/bewellsalud"> <img class="text-right"
              src="../assets/logo/linkedinLogo.svg" alt="" /></a-button>
          <br><br>
        </a-col>
        <a-col>
          <a-typography-paragraph class="fs-xs mb-0">Somos parte de empresas </a-typography-paragraph>
          <img src="../assets/logo/sableLogo.svg" alt="">
          <br>
          <br>
          <a-row :gutter="[{ xs: 12, sm: 12, md: 24 }]">
            <a-col :span="8"><img class="img-fluid" src="../assets/logo/mandomedioLogo.svg" alt=""></a-col>
            <a-col :span="6"><img class="img-fluid" src="../assets/logo/rex+Logo.svg" alt=""></a-col>
            <a-col :span="8"><img class="img-fluid" src="../assets/logo/logoManager.svg" alt=""></a-col>
          </a-row>
        </a-col>
      </a-row>
      <br>
      <br>
      <a-row>
        <a-col>
          <a-typography-paragraph class="fs-xs">
            <a-typography-text strong> © 2022, bewell.</a-typography-text> Todos los derechos reservados
          </a-typography-paragraph>
        </a-col>
      </a-row>
    </div>
  </div>
  <a href="https://api.whatsapp.com/send?phone=56946931921&amp;text=%20Me%20gustar%C3%ADa%20saber%20m%C3%A1s%20de%20bewell"
    class="float" target="_blank" rel="noreferrer">
    <WhatsAppOutlined class="my-float" />
  </a>
  <HomeOutlined />
</template>

<script>
import {
  WhatsAppOutlined,

} from '@ant-design/icons-vue';

export default {
  name: 'FooterSection',
  components: {
    WhatsAppOutlined
  }
}
</script>

