<template>
  <LandingView />
</template>

<script>
import LandingView from './views/LandingView.vue'

export default {
  name: 'App',
  components: {
    LandingView
  }
}
</script>

