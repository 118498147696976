<template>
  <div class="blue-linear-gradiant page3-padding">
    <div class="container-xxl">
      <a-row :gutter="[{ xs: 0, sm: 16, md: 24, lg: 32 }, { xs: 32, sm: 16, md: 24, lg: 32 }]">
        <a-col :xs="24" :md="12" :xl="14" class="visible-lg visible-xl">
          <img class="img-fluid" src="../assets/reportabilityImg.png" alt="banner" />
        </a-col>
        <a-col :xs="24" :xl="10">
          <a-space direction="vertical">
            <a-typography-text strong class="text-gray-1 ">Nuestra Plataforma</a-typography-text>
            <a-typography-title :level="2" class="text-gray-1 ff-urbanist">Reportabilidad en línea
            </a-typography-title>

            <a-typography-text class="text-gray-1 fs-xl">Obtén información al instante con nuestro panel de reportes.
              Previene
              desviaciones y proyecta resultados futuros para que tus colaboradores sean conscientes de sus beneficios.
            </a-typography-text>
            <br class="visible-xl ">
            <a-space direction="vertical" size="large">

              <a-space align="middle">
                <img src="../assets/icon/checkIcon.png" width="16" alt="">
                <a-typography-text strong class="text-gray-1 fs-xl">Estado de altas, bajas y modificaciones.
                </a-typography-text>
              </a-space>

              <a-space align="middle">
                <img src="../assets/icon/checkIcon.png" width="16" alt="">
                <a-typography-text strong class="text-gray-1 fs-xl">Información de siniestralidad.</a-typography-text>
              </a-space>

              <a-space align="middle">
                <img src="../assets/icon/checkIcon.png" width="16" alt="">
                <a-typography-text strong class="text-gray-1 fs-xl">Estado de vigencia de los colaboradores en los
                  seguros.
                </a-typography-text>
              </a-space>
            </a-space>
          </a-space>

        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page3Component',
}
</script>

