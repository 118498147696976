<template>
  <div class="container-xxl">
    <a-row justify="center" align="middle" style=" padding: 0 2rem 0.5rem 2.5rem;">
      <a-col :xs="24" :lg="6" class="text-center mb-2">
        <a-typography-text strong class="fs-xl">Somos regulados por</a-typography-text>
      </a-col>
      <a-col :xs="24" :lg="6" class="text-center"><img class="img-fluid" src="../assets/logo/ministryLogo.svg" alt="">
      </a-col>
    </a-row>
    <br>
    <div class="page1-padding">
      <a-row justify="center">
        <a-col :span="24" :md="14">
          <a-typography-title :level="2" class="ff-urbanist fw-regular text-center ">
            <a-typography-text type="secondary" class="fw-semibold">Experiencia y digitalización
            </a-typography-text> en gestión de beneficios y seguros para colaboradores
          </a-typography-title>
        </a-col>
      </a-row>
      <br>
      <a-row justify="center">
        <a-col :span="24" :md="16">
          <a-typography-paragraph class="text-center fs-xl">En bewell somos profesionales con más de 25 años en gestión
            de
            beneficios
            y seguros. Formamos
            parte del Grupo Sable, cuya misión es simplificar el día a día a través de ecosistemas digitales.
          </a-typography-paragraph>
        </a-col>
      </a-row>
      <br>
      <br>
      <a-row :gutter="16" justify="space-around" align="bottom">
        <a-col :xs="18" :sm="14" :lg="6" class="text-center">
          <img class="text-center" src="../assets/illustration1.svg" alt="">
          <div class="hidden-md hidden-lg hidden-xl">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Atrae y reten el talento <br> de tu organización
            </a-typography-paragraph>
            <a-typography-paragraph>Beneficios a la medida de tus colaboradores con una experiencia
              digital.
            </a-typography-paragraph>
          </div>
        </a-col>
        <a-col :xs="18" :sm="14" :lg="6" class="text-center">
          <img src="../assets/illustration2.svg" alt="">
          <div class="hidden-md hidden-lg hidden-xl">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Ahorra costos y tiempo en <br> corretaje de seguros
            </a-typography-paragraph>
            <a-typography-paragraph>Asesoría en modelos asegurables, negociación efectiva y plataforma que
              baja los costos de administración.
            </a-typography-paragraph>
          </div>
        </a-col>
        <a-col :xs="18" :sm="14" :lg="6" class="text-center">
          <img src="../assets/illustration3.svg" alt="">
          <div class="hidden-md hidden-lg hidden-xl">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Reportabilidad <br> en línea</a-typography-paragraph>
            <a-typography-paragraph>Disponemos de información en línea para la administración y gestión.
            </a-typography-paragraph>
          </div>
        </a-col>
      </a-row>
      <div class="visible-md visible-lg visible-xl">
        <a-row :gutter="16" justify="space-around">
          <a-col :span="24" :md="6" class="text-center">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Atrae y reten el talento de tu organización
            </a-typography-paragraph>
            <a-typography-paragraph>Beneficios a la medida de tus colaboradores con una experiencia
              digital.
            </a-typography-paragraph>
            <br><br><br>
          </a-col>


          <a-col :span="24" :md="6" class="text-center">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Ahorra costos <br> y tiempo en corretaje de seguros
            </a-typography-paragraph>
            <a-typography-paragraph>Asesoría en modelos asegurables, negociación efectiva y plataforma que
              baja los costos de administración.
            </a-typography-paragraph>
          </a-col>
          <a-col :span="24" :md="6" class="text-center">
            <br>
            <a-typography-paragraph class="fs-xl" strong>Reportabilidad <br> en línea</a-typography-paragraph>
            <a-typography-paragraph>Disponemos de información en línea para la administración y gestión.
            </a-typography-paragraph>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page1Component',
}
</script>

